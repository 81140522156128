import React from 'react'
import { graphql } from 'gatsby'
import { PageContextProvider } from '@context'
import PageContainer from '@system/page-container'
import makeStyles from '@material-ui/styles/makeStyles'
import { Box, Link, Typography } from '@material-ui/core'
import { H1, H4, H6 } from '@system'
import useStringTranslation from '@hooks/use-string-translation'
import new404 from '../images/new404.png'
import ContentContainer from '@system/content-container'
import ACC_THEME from '@themes'
import TopNavStory from '@components/stories/top-nav-story'
import BottomNavStory from '@components/stories/bottom-nav-story'
import { ThemeProvider } from '@material-ui/styles'
import { localizedSiteData } from '@variables/global-variables'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    paddingTop: '69px',
    paddingBottom: '69px',
    [ACC_THEME.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    maxHeight: '622px',
    maxWidth: '693px',
  },
  title: {
    fontWeight: ACC_THEME.typography.fontWeightExtraBold,
    color: ACC_THEME.palette.primary.main,
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    [ACC_THEME.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  right: {
    [ACC_THEME.breakpoints.down('lg')]: {
      width: '100%',
      paddingLeft: '52px',
    },
  },
  description: {
    fontWeight: ACC_THEME.typography.fontWeightRegular,
    color: ACC_THEME.palette.text.tertiary,
  },
  linksContainer: {
    [ACC_THEME.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacer: {
    padding: '10%',
  },
  linksHeader: {
    fontWeight: ACC_THEME.typography.fontWeightRegular,
    color: ACC_THEME.palette.text.primary,
  },
  linkTitle: {
    fontWeight: ACC_THEME.typography.fontWeightRegular,
    color: ACC_THEME.palette.text.primary,
  },
}))

const PageNotFound = (props) => {
  const classes = useStyles(props)
  const baseUrl = localizedSiteData[process.env.GATSBY_LOCALE || 'us'].url

  const staticlinks = [
    { title: 'Home', link: `${baseUrl}/` },
    { title: 'Products', link: `${baseUrl}/products/` },
    { title: 'Resources', link: `${baseUrl}/` },
    { title: 'Help', link: `${baseUrl}/contact/` },
    { title: 'Blog', link: 'https://constructionblog.autodesk.com/' },
  ]

  return (
    <PageContainer>
      <ThemeProvider theme={ACC_THEME}>
        <ContentContainer>
          <Box className={classes.root}>
            <Box className={classes.left}>
              <Box>
                <H1 className={classes.title}>
                  {useStringTranslation('Oops!')}
                </H1>
                <H4 className={classes.description}>
                  {useStringTranslation(
                    "We can't seem to find the page you are looking for."
                  )}
                </H4>
              </Box>
              <Box className={classes.spacer} />
              <Box className={classes.linksContainer}>
                <H6 className={classes.linksHeader}>
                  {useStringTranslation('Here are some helpful links instead')}:
                </H6>
                <Box className={classes.links}>
                  {staticlinks.map((item, index) => (
                    <Link key={index} href={item.link}>
                      <Typography className={classes.linkTitle}>
                        {item.title}
                      </Typography>
                    </Link>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box className={classes.right}>
              <img
                className={classes.image}
                src={new404}
                alt="page not found"
              />
            </Box>
          </Box>
        </ContentContainer>
      </ThemeProvider>
    </PageContainer>
  )
}

const NotFoundPage = ({
  data: {
    storyblokEntry: { content, ...data },
  },
}) => {
  return (
    <PageContextProvider {...data}>
      <TopNavStory />
      <PageNotFound />
      <BottomNavStory />
    </PageContextProvider>
  )
}

export const query = graphql`
  query NotFoundPageQuery {
    storyblokEntry(slug: { eq: "page-not-found" }) {
      id
      uuid
      slug
      full_slug
      name
      created_at
      group_id
      internalId
      is_startpage
      lang
      parent_id
      path
      position
      published_at
      tag_list
      alternates {
        full_slug
        id
        is_folder
        name
        parent_id
        published
        slug
      }
      content
    }
  }
`

export default NotFoundPage
