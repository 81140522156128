import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import TopNav from '@blok/top-nav'

const TopNavStory = (props) => {
  const {
    storyblokEntry: { content },
  } = useStaticQuery(graphql`
    query TopNavStoryQuery {
      storyblokEntry(
        full_slug: { in: "us/_content-types/acc-top-nav-unified" }
      ) {
        id
        uuid
        slug
        full_slug
        name
        created_at
        group_id
        internalId
        is_startpage
        lang
        parent_id
        path
        position
        published_at
        tag_list
        alternates {
          full_slug
          id
          is_folder
          name
          parent_id
          published
          slug
        }
        content
      }
    }
  `)
  const pageData = JSON.parse(content)
  return <TopNav blok={pageData} {...props} />
}

export default TopNavStory
